import React from "react";
import { StaticQuery, graphql } from "gatsby";
import TournamentList from "../components/TournamentList";

import Layout from "../components/layout";
import "./tournament.css";


const Tournaments = () => (
  <Layout>
    <h1>TOURNAMENTS</h1>
    <StaticQuery 
      query={graphql`
        query tournamentQuery {
          upcoming:allTournamentsJson(sort: {fields: index, order: ASC}) {
           edges {
            node {
              index
              name
              game
              finished
              date
            }
           }
          }
          finished:allTournamentsJson(sort: {fields: index, order: DESC}) {
           edges {
            node {
              index
              name
              game
              finished
              date
            }
           }
          }
          images:allFile(filter: {relativeDirectory: {eq: "games"}}) {
            edges {
              node {
                name
                childImageSharp {
                  fixed(width: 450, height: 300) {
                    src
                  }
                }
              }
            }
          }
        }
      `}

      render={data => {
        return (
          <div className="body">
            <TournamentList data={data}></TournamentList>
          </div>
        );
      }}
    />
  </Layout>
);

export default Tournaments;
