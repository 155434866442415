import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import TournamentTab from "./TournamentTab";
import findImage from "./helpers/findImage";

class TournamentList extends Component {

  state = {
    selectedTournaments: "upcoming"
  }

  activeTab = (view) => {
    this.setState(state => ({ selectedTournaments: view }));
  }

  render() {
    const {
      selectedTournaments
    } = this.state;
    const {
      data
    } = this.props;
    const finishedTournaments = data.finished.edges.filter( el  => {
      if(el.node.finished) {
        return true;
      }
      return false;
    }).map( el => { return el.node; });
    const upcomingTournaments = data.upcoming.edges.filter( el  => {
      if(!el.node.finished) {
        return true;
      }
      return false;
    }).map( el => { return el.node; });

    return (
      <>
        <TournamentTab selectedTournaments={selectedTournaments} onClick={this.activeTab}></TournamentTab>
        <div className={`upcoming ${selectedTournaments === "upcoming" ? "active" : ""}`}>
          {
            upcomingTournaments.map(tournament => {
              return (
                <div className='tournament__item' key={tournament.index} style={{
                  background: `url(${findImage(tournament.game, data.images)}) no-repeat`,
                }}>
                  <h3 className='tournament__name'>{tournament.name}</h3>
                  <div className='tournament__info'>
                    <div className='tournament__game'>{tournament.game}</div>
                    <div className='tournament__date'>{`${tournament.date ? tournament.date : ""}`}</div>
                  </div>
                  <Link to={`/tournament/${tournament.index}/`} className='tournament__link'>Read more</Link>
                </div>
              );
            })
          }
        </div>
        <div className={`finished ${selectedTournaments === "finished" ? "active" : ""}`}>
          {
            finishedTournaments.map(tournament => {
              return (
                <div className='tournament__item' key={tournament.index} style={{
                  background: `url(${findImage(tournament.game, data.images)}) no-repeat`,
                }}>
                  <h3 className='tournament__name'>{tournament.name}</h3>
                  <div className='tournament__info'>
                    <div className='tournament__game'>{tournament.game}</div>
                    <div className='tournament__date'>{`${tournament.date ? tournament.date : ""}`}</div>
                  </div>
                  <Link to={`/tournament/${tournament.index}/`} className='tournament__link'>Read more</Link>
                </div>
              );
            })
          }
        </div>
      </>
    );
  }
}

TournamentList.propTypes = {
  data: PropTypes.object.isRequired
};

export default TournamentList;
