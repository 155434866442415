const findImage = function (game, data) {
  const cleanedGame = game.replace(/[^A-Z0-9]/ig, "");
  let activeImage = "";
  data.edges.filter( el => {
    if(cleanedGame === el.node.name) {
      activeImage = el.node.childImageSharp.fixed.src;
    }

    return true;
  });
  return activeImage;
};

export default findImage;