import React, { Component } from "react";
import PropTypes from "prop-types";

class TournamentTab extends Component {

  render() {
    const {
      selectedTournaments,
      onClick
    } = this.props;

    return (
      <div className="tournament__tab">
        <div
            role="button"
            onClick={() => onClick("upcoming")}
            onKeyPress={() => onClick("upcoming")}
            className={`tournament__tab-upcoming ${selectedTournaments === "upcoming" ? "active" : ""}`}
        >
          <span>UPCOMING</span>
        </div>
        <div
            role="button"
            onClick={() => onClick("finished")}
            onKeyPress={() => onClick("finished")}
            className={`tournament__tab-finished ${selectedTournaments === "finished" ? "active" : ""}`}
        >
          <span>FINISHED</span>
        </div>
      </div>
    );
  }
}

TournamentTab.propTypes = {
  selectedTournaments: PropTypes.string,
  onClick: PropTypes.func
};

export default TournamentTab;
